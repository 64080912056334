import { CheckoutStatus } from '../model';
import { isIn } from './dataUtils';

export const isFinal = (status: CheckoutStatus) =>
  isIn(status, [
    CheckoutStatus.SUCCESS,
    CheckoutStatus.ERROR,
    CheckoutStatus.EXPIRED,
    CheckoutStatus.CANCELLED,
    CheckoutStatus.AWAITING_CONFIRMATION,
    CheckoutStatus.PROCESSING_CONFIRMATION,
  ]);
