import { Feature } from './features';

export type CheckoutIdentifier = string;
export type PaymentIdentifier = string;

/** Checkout item (v2) */
export interface CheckoutItem {
  /** Unique identifier for the item in cart */
  id: string;
  /** Used-supplied group of the checkout item */
  group?: string;
  /** The name of the item (displayed on checkout)  */
  name: string;
  /** The type of the checkout item, eg. flight/tax/extra bag etc...*/
  type: CheckoutItemType;
  /** Service code of the checkout item. Required for manual confirmation checkouts */
  ssrCode?: string;
  /** Can the item payment be split between points and money */
  splitWithPointsSupported: boolean;
  /** Can the item be paid with a gift card */
  payableWithGiftCard: boolean;
  /** If paid by credit card, do we apply a surcharge? */
  surchargingApplies?: boolean;
  /** Total Price */
  price: CheckoutItemPrice;
  /** The unique ID of the passenger in the itinerary to whom this item belongs to */
  passengerId?: number;
  /** The product name used for loyalty transactions */
  productName?: string;
}

export enum CheckoutItemType {
  TAX = 'tax',
  FLIGHT = 'flight',
  EXTRA_BAGGAGE = 'extra-baggage',
  MEAL = 'meal',
  SEAT = 'seat',
  WIFI = 'wifi',
  LOUNGE = 'lounge',
  CANCELLATION_COVER = 'cancellation-cover',
  PET = 'pet',
  BICYCLE = 'bicycle',
  SAF = 'saf',
  DEFAULT = 'default',
}

export const MONETARY_CURRENCIES = [
  'AED',
  'SEK',
  'AUD',
  'CAD',
  'CHF',
  'CNY',
  'CZK',
  'DKK',
  'GBP',
  'HKD',
  'HUF',
  'SGD',
  'USD',
  'INR',
  'JPY',
  'THB',
  'KRW',
  'NOK',
  'PLN',
  'RUB',
  'TRY',
  'TWD',
  'UAH',
  'EUR',
] as const;

/** Currency code (ISO 4217). */
export type MonetaryCurrency = (typeof MONETARY_CURRENCIES)[number];

const CURRENCIES_NOT_SUPPORTING_SPLIT_POINTS = ['TWD', 'UAH'];

export const CURRENCIES_SUPPORTING_SPLIT_POINTS: string[] = MONETARY_CURRENCIES.filter(
  (c) => !CURRENCIES_NOT_SUPPORTING_SPLIT_POINTS.includes(c)
);

export const CHECKOUT_ITEM_PRICE_DEFAULTS = ['monetary', 'points'] as const;
export type SelectedCheckoutItemPrice = (typeof CHECKOUT_ITEM_PRICE_DEFAULTS)[number];

export interface CheckoutItemPrice {
  /** Set monetary value */
  monetary?: CheckoutItemMonetaryPrice;
  /** Or set points value (or both) */
  points?: CheckoutItemPointsPrice;
  /** Is the price the default selection */
  selected?: SelectedCheckoutItemPrice;
}

export interface CheckoutItemMonetaryPrice {
  /** amount as stringified decimal number with dot as a decimal separator */
  amount: string;
  /** Currency code (ISO 4217). */
  currency: MonetaryCurrency;
}

export type CheckoutItemPointsPrice = number;

export interface CheckoutItemPayment {
  /** The item ID */
  itemId: string;
  /** The payment ID */
  paymentId: string;
  /** The amount of the payment that is used for the item. Does not include surcharge */
  amount: string;
  /** The surcharge amount */
  surcharge?: string;
}

/** Purchase item */
export interface PaymentItem {
  /** Product code, eg. SKU or itinerary number */
  articleNumber?: string;
  /** Currency code (ISO 4217). */
  currency: string;
  /** Product name */
  name: string;
  /** Price as stringified decimal number with dot as a decimal separator */
  price: string;
  quantity: number;
  /** VAT percentage */
  vat?: string;
  /** Service code of the checkout item. Required for manual confirmation checkouts */
  ssrCode?: string;
}

export interface PaymentItemWithType extends PaymentItem {
  /** Type of the item */
  type?: PaymentItemType;
}

export enum PaymentItemType {
  BAG = 'BAG',
  FLIGHT = 'FLIGHT',
  MEAL = 'MEAL',
  SEAT = 'SEAT',
  SHOPPING = 'SHOPPING',
  TREAT = 'TREAT',
  WIFI = 'WIFI',
}

export enum PaymentGroup {
  CARD = 'CARD',
  /** Alternative Methods of Payment */
  AMOP = 'AMOP',
  /** Stored credit/debit cards aka 1-click */
  TOKEN = 'TOKEN',
  ONLINE_BANK = 'ONLINE_BANK',
}

export type SelectablePaymentType = Exclude<PaymentType, PaymentType.TOKEN>;

export enum PaymentType {
  PAYMENT_CARD = 'PAYMENT_CARD',
  TOKEN = 'TOKEN',
  DEBIT_TOKEN = 'DEBIT_TOKEN',
  CREDIT_TOKEN = 'CREDIT_TOKEN',

  ALIPAY = 'ALIPAY',
  ALIPAY_WAP = 'ALIPAY_WAP',
  WECHAT = 'WECHAT',
  UNIONPAY = 'UNIONPAY',
  APPLE_PAY = 'APPLE_PAY',
  IDEAL = 'IDEAL',
  SOFORT = 'SOFORT',
  GIROPAY = 'GIROPAY',
  SWISH = 'SWISH',
  VIPPS = 'VIPPS',
  TRUSTLY = 'TRUSTLY',
  KLARNA = 'KLARNA',
  KLARNA_PAYNOW = 'KLARNA_PAYNOW',
  KLARNA_ACCOUNT = 'KLARNA_ACCOUNT',
  MOBILE_PAY = 'MOBILE_PAY',

  //Adyen India
  INDIA_WALLET = 'INDIA_WALLET',
  INDIA_UPI = 'INDIA_UPI',
  INDIA_NETBANKING = 'INDIA_NETBANKING',

  // Adyen card payments
  ADYEN_PAYMENT_CARD = 'ADYEN_PAYMENT_CARD',

  // Online Banks
  NORDEA = 'NORDEA',
  NORDEA_ECREDIT = 'NORDEA_ECREDIT',
  NORDEA_EE = 'NORDEA_EE',
  SAMPO = 'SAMPO',
  OKO = 'OKO',
  HANDELSBANKEN = 'HANDELSBANKEN',
  ALANDSBANKEN = 'ALANDSBANKEN',
  SPANKKI = 'SPANKKI',
  AKTIA = 'AKTIA',
  SP = 'SP',
  POP = 'POP',

  // Secondary payments
  GIFT_CARD = 'GIFT_CARD',
  POINTS = 'POINTS',

  // Only for testing
  ADYEN_SIMULATOR = 'ADYEN_SIMULATOR',
}

export enum PaymentIdPrefix {
  GIFT_CARD = 'gift-card',
  POINTS_PAYMENT = 'points-payment',
}

export interface PaymentLogo {
  href: string;
  alt: string;
  iconName?: string;
}

export interface PaymentLogos {
  acceptanceLogos?: PaymentLogo[];
  paymentLogo?: PaymentLogo;
  leftAdjustedPaymentLogo?: PaymentLogo;
}

export interface PaymentMethod {
  type: PaymentType;
  group: PaymentGroup;
  /** Localized plain text title */
  title: string;
  selectionUrl?: string; // TODO this is not mandatory but empty can't be handled at the moment
  authorizationUrl?: string;
  logos: PaymentLogos; // Actually nullable
  additionalData?: ApplePayInitData | OneClickInitData;
  location?: string;
  data?: string; // Content for selected method, eg HTML snippets.
  savedCards?: SavedCard[];
  adyenSession?: AdyenSessionData;
  adyenClientKey?: string;
}

export interface CheckoutPaymentMethod extends PaymentMethod {
  adyenSession?: AdyenCheckoutSession;
}

export interface PaymentMethodGroups {
  [group: string]: PaymentType[];
}

export interface AdyenSessionData {
  id: string;
  sessionData: string;
}

export type AdyenEnvironment = 'test' | 'live';

export interface AdyenCheckoutSession extends AdyenSessionData {
  clientKey: string;
  adyenEnvironment: AdyenEnvironment;
}

export interface UserDevice {
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
}

export type Airport = {
  iata: string;
  cityName: string;
};

export type FlightLegInfo = {
  departure: Airport;
  arrival: Airport;
};

export type Flight = {
  arrivalCity: string;
  departureCity: string;
  departureDate: string;
};

export type FlightsByBounds = {
  [key: string]: Flight;
};

export enum TripType {
  ROUND = 'round',
  ONEWAY = 'oneway',
  MULTICITY = 'multicity',
}

export type TripDetails = {
  flightsByBounds: FlightsByBounds;
  tripType: TripType;
  passengerCount: number;
};

export enum UserNotificationType {
  INFO = 'info',
  WARNING = 'warning',
}

export interface UserNotificationTitle {
  key: string;
  fallback: string;
}

export interface UserNotificationDescription {
  key: string;
  fallback: string;
}

export interface UserNotification {
  type: UserNotificationType;
  title?: UserNotificationTitle;
  description: UserNotificationDescription;
  condition?: object;
  readMoreUrl?: string;
  readMoreText?: string;
  isCloseable?: boolean;
  isGlobalDisturbancePlaceholder?: boolean; // Use this field to indicate if notification should be hidden if there are any global disturbances
}

export enum Severity {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Alert = 'alert',
}

export type NotificationData = {
  id: string;
  text: string;
  title?: string;
  severity?: Severity;
  readMoreUrl?: string;
  readMoreText?: string;
  isCloseable?: boolean;
};

export enum NotificationComponent {
  Generic = 'generic', // Shown on top of the page
  CashPayments = 'cashPayments', // Shown below payment method header
  FinnairPayments = 'finnairPayments', // Shown below Finnair Payments card
  PointsPage = 'pointsPage', // Shown on points page
}

export type RedesignedNotificationData = NotificationData & { component: NotificationComponent };

export interface Checkout {
  id: string;
  uniqueReferenceId: string;
  expires: number;
  paymentMethodGroups?: PaymentMethodGroups;
  paymentMethods?: CheckoutPaymentMethod[];
  items?: PaymentItemWithType[];
  totalPrice: Price;
  status: CheckoutStatus;
  returnUrl?: string;
  cancelUrl?: string;
  expirationUrl: string;
  country: string;
  channel?: CheckoutChannel;
  finnairAppVersion?: string;
  locale: string;
  userAgent?: string;
  userDevice?: UserDevice;
  surcharging?: SurchargingRuleWithLogo[];
  loggedInUser: boolean;
  loggedInUserDisplayName?: string;
  acsRedirectData?: AcsRedirectData;
  redirectedThreeDs?: number;
  cardinalData?: CardinalData;
  externalAuthenticationMethod?: ExternalAuthenticationMethod;
  surchargeablePrice?: Price;
  tokens?: number;
  flightLegInfo?: FlightLegInfo;
  tripDetails?: TripDetails;
  payments?: Payments;
  remainingPrice: Price;
  remainingSurchargeablePrice?: Price;
  giftCardEnabled: boolean;
  splitPointsEnabled: boolean;
  loyaltyPoints?: number;
  checkoutItems: CheckoutItem[];
  itemPayments?: CheckoutItemPayment[];
  recloc?: string;
  service: string;
  termsAndConditions?: TermsAndConditions;
  userNotifications?: UserNotification[];
  featureFlags?: FeatureFlags;
}

export interface TermsAndConditions {
  title: string;
  content: string;
}

type FeatureFlags = { [key in Feature]?: boolean };

export enum PaymentUsage {
  IFRAME = 'IFRAME',
  REDIRECT = 'REDIRECT',
}

export interface PaymentMethodDetails {
  /** Payment type. NOT returned from the api. Must be set manually! */
  type: PaymentType;
  /** redirect or iFrame URL */
  location?: string;
  /** Additional data, e.g. Apple Pay merchant Id */
  data?: string;
  /** Timestamp */
  timestamp?: string;
  /** How is this payment method used, e.g. render an iFrame */
  usage?: PaymentUsage;
}

export interface PaymentMethodSelection {
  type: PaymentType;
  selectionUrl: string;
}

// == ApplePay ==

export interface ApplePayInitData {
  merchantId: string;
  merchantCapabilities: string[];
  supportedNetworks: string[];
}

export interface ApplePayParameters {
  validationOrigin: string;
  validationUrl: string;
}

export interface ApplePayAuthorizeParameters {
  token: string;
}

export interface AuthorizeResponse {
  success: boolean;
}

export interface BrowserInfo {
  colorDepth?: number;
  javaEnabled?: boolean;
  language?: string;
  screenHeight?: number;
  screenWidth?: number;
  timeZoneOffset?: number;
  userAgent?: string;
  acceptHeader?: string;
}

export interface RiskData {
  clientData?: string;
}

export interface CardDetails {
  type?: string;
  brand?: string;
  holderName?: string;
  encryptedCardNumber?: string;
  encryptedExpiryMonth?: string;
  encryptedExpiryYear?: string;
  encryptedSecurityCode?: string;
  checkoutAttemptId?: string;
}

export interface BillingAddress {
  street?: string;
  houseNumberOrName?: string;
  postalCode?: string;
  city?: string;
  stateOrProvince?: string;
  country?: string;
}

export interface AdyenData {
  issuer?: string;
  paymentType?: string;
  virtualPaymentAddress?: string;
  browserInfo?: BrowserInfo;
  riskData?: RiskData;
  cardDetails?: CardDetails;
  billingAddress?: BillingAddress;
}

export interface SelectPaymentMethodParameters {
  applePay?: ApplePayParameters;
  savedCardToken?: string;
  adyenAdditionalData?: string;
  adyenData?: AdyenData;
  channel?: CheckoutChannel;
}

export enum CheckoutChannel {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
}

// == 1-click ==

export interface Token {
  token: string;
  expiry: string;
  vendor: Vendor;
  cardType: CardType;
  cardHolder: string;
  billingAddress: string;
  postCode: string;
  city: string;
  country: string;
  name: string;
  corporate?: boolean; // TODO make mandatory at some point?
  isDisabled?: boolean;
}

export interface TokenId {
  token: string;
}

export interface OneClickInitData {
  token: Token;
}

export interface SavedCard {
  token: Token;
  logo: PaymentLogo;
  selectionUrl: string;
}

// == Payment Details / Summary ==

export interface Purchase {
  items: PaymentItem[];
  totalPrice: Price;
}

export enum CheckoutStatus {
  CREATED = 'CREATED',
  INITIALIZING = 'INITIALIZING',
  READY_FOR_PAYMENT = 'READY_FOR_PAYMENT',
  METHOD_SELECTED = 'METHOD_SELECTED',
  APPLE_PAY_AUTHORIZED = 'APPLE_PAY_AUTHORIZED',
  APPLE_PAY_DECLINED = 'APPLE_PAY_DECLINED',
  PROCESSING = 'PROCESSING',
  REDIRECTED = 'REDIRECTED',
  REDIRECTED_THREEDS = 'REDIRECTED_THREEDS',
  PROCESSING_THREEDS = 'PROCESSING_THREEDS',
  INVALID_PAYMENT_METHOD = 'INVALID_PAYMENT_METHOD',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  CARDINAL_INITIALIZED = 'CARDINAL_INITIALIZED',
  CARDINAL_CHALLENGED = 'CARDINAL_CHALLENGED',
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
  PROCESSING_CONFIRMATION = 'PROCESSING_CONFIRMATION',
}

export interface Price {
  /** The amount of money. The decimal separator is always a dot. */
  amount: string;
  /** Currency code (ISO 4217) the price is in */
  currencyCode: string;
  /** Exchange rate from Euro. If currency is Euro then it should be "1" */
  exchangeRate?: string;
}

/** Surcharging ruleset for a specific card type */
export interface SurchargingRule {
  vendor: Vendor;
  /** Whether the rule applies to corporate cards or not */
  corporate: boolean;
  /** The surcharging percentage to apply for the card type. The decimal separator is always a dot. */
  percentage: string | null;
  /** The human-readable, localized name for the rule. Might be "Visa Corporate", for example. */
  displayName?: string;
  cardType?: CardType;
}

export interface SurchargingRuleWithLogo extends SurchargingRule {
  logo?: PaymentLogo;
}

/** Shortcode for vendor */
export enum Vendor {
  AX = 'AX',
  CA = 'CA',
  DC = 'DC',
  DS = 'DS',
  JC = 'JC',
  TP = 'TP',
  VI = 'VI',
}

export enum CardType {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export type PaymentCard = {
  vendor?: Vendor;
  cardType?: CardType;
  corporate: boolean;
};

export type AcsRedirectData = {
  acsUrl: string;
  method: string;
  paReq?: string;
  md: string;
  termUrl?: string;
  transactionId?: string;
  cReq?: string;
};

export type AcsData = {
  xid?: string;
  redirectData: AcsRedirectData;
};

export interface CardinalData {
  session?: CardinalSessionData;
  initializationJwt?: string;
  challenge3DSResponse?: string;
}

export interface CardinalSessionData {
  sessionId: string;
}

export enum ExternalAuthenticationMethod {
  NONE = 'none',
  CARDINAL = 'cardinal',
  PCI = 'pci',
}

export interface MerchantDataWithoutHmac {
  checkoutId: CheckoutIdentifier;
}

export interface MerchantData extends MerchantDataWithoutHmac {
  hmac: string;
}

/** Gift card information */
export interface GiftCard {
  /** The unique ID of the gift card */
  id: string;
  /** Initial value of gift card when it was created, eg. "1000.00" */
  initialValue: string;
  /** Remaining value on gift card, eg. "500.00" */
  remainingValue: string;
  /** Currency code of gift card, eg. "EUR" */
  currency: string;
  /** Expiry date, eg. "2020-05-18T11:25:12Z" */
  expiryDate: string;
  /** Payment identifier, eg. "gift-card" */
  paymentId: string;
  /** Expired value on gift card, eg. "50.00" */
  expiredValue?: string;
}

/** Gift card information as applied to a checkout */
export interface AppliedGiftCard extends GiftCard {
  /** How much value can be redeemed for the current checkout */
  appliedValue?: string;
  /** Value remaining on gift card after redemption of "appliedValue" */
  valueAfterRedemption?: string;
}

/**
 * Request to apply gift card to checkout
 */
export interface ApplyGiftCardRequest {
  giftCardId: string;
  pin: string;
}

/** Gift card status */
export enum ApplyGiftCardStatus {
  APPLIED = 'applied',
  CANCELLED = 'cancelled',
  CONVERTED = 'converted',
  EXPIRED = 'expired',
  LOCKED = 'locked',
  EMPTY = 'empty',
}

/**
 * Gift card information as applied to the current checkout
 */
export interface ApplyGiftCardResponse {
  status: ApplyGiftCardStatus;
  giftCard: AppliedGiftCard;
}

export interface PointPayment {
  amount: string;
  checkoutItemId: string;
  paymentId: string;
  transactionId?: string;
  externalTransactionId?: string;
}

export interface Payments {
  giftCards?: AppliedGiftCard[];
  points?: PointPayment[];
}

export interface StepFunctionEvent {
  checkoutId: string;
}

export interface ExpireEvent extends StepFunctionEvent {
  timestamp: string;
  expireAwaitingConfirmationCheckout?: boolean;
}

export interface PublishSnsEvent {
  snsTopicSuffix?: string;
  subject: string;
  message: string;
  name: string;
  date: string;
}

export const redemptionErrorCode = 'giftCard-redemption-error';
export const pointsTransactionErrorCode = 'points-transaction-error';

export interface ApplyPoints {
  amount: number;
  checkoutItemIds: string[];
}

export type ApplyPointsRequest = ApplyPoints[];

export interface StoreCardinalSessionRequest {
  sessionId: string;
}

export interface StoreChallengeResponseRequest {
  challenge3DSResponse: string;
}

export enum ConfirmationMode {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}
