import { Checkout, CheckoutChannel } from '@checkout/shared/src/model';
import { isPresent } from '@checkout/shared/src/util';
import { getDateTimeFormat } from './dateTimeFormat';
import { getCheckoutUrl } from './urls';

// Todo: Find out a better way to accomplish this, this is here to be able to
// access the Window in typescript
declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    ApplePaySession?: any;
    webkit: any;
    nativeBridge: any;
    Android: any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }
}

const fallbackLocale = 'en';

export const parseLocale = (path: string): string =>
  path.split('/').length < 3 ? fallbackLocale : path.split('/').filter((val) => val)[0];

export const convertLocaleToIeTf = (locale: string): string => locale.replace('_', '-');

export const isRedirectSuccessPage = (url?: string): boolean => (url ?? window.location.pathname).endsWith('/success');
export const isRedirectFailPage = (url?: string): boolean => (url ?? window.location.pathname).endsWith('/fail');
export const isRedirectCancelPage = (url?: string): boolean => (url ?? window.location.pathname).endsWith('/cancel');
export const isRedirectReturnPage = (url?: string): boolean =>
  isRedirectSuccessPage(url) || isRedirectFailPage(url) || isRedirectCancelPage(url);
export const isShopNotificationErrorPage = (url?: string): boolean =>
  (url ?? window.location.pathname).endsWith('/shop-notification-failure');

export const getClientLocale = (): string => parseLocale(convertLocaleToIeTf(window.location.pathname));
export const getClientLanguage = (): string => getClientLocale().split('-')[0];

export const isNativeFinnairIOSApp = (): boolean =>
  isPresent(window.webkit) && isPresent(window.webkit.messageHandlers.isNativeFinnairIosApp);

export const isNativeFinnairAndroidApp = (): boolean => isPresent(window.Android);

export const isFinnairApp = (): boolean => isNativeFinnairAndroidApp() || isNativeFinnairIOSApp();

export const getCheckoutChannel = (): CheckoutChannel => {
  if (isNativeFinnairAndroidApp()) return CheckoutChannel.ANDROID;
  if (isNativeFinnairIOSApp()) return CheckoutChannel.IOS;
  return CheckoutChannel.WEB;
};

export const scrollToTop = (offset?: number): void => {
  if (!window.scrollTo) return;
  window.scrollTo(0, offset ?? 0);
};

export const getElementOffsetHeight = (selector: string): number => {
  const element: HTMLElement | null = document.querySelector(selector);
  return isPresent(element) ? element.offsetHeight : 0;
};

export const isBrowserUsingHttps = (): boolean =>
  Boolean(window && window.location && window.location.protocol === 'https:');

export const createApplePaySession = (
  version: number,
  applePayRequest: ApplePayJS.ApplePayPaymentRequest
): ApplePaySession => {
  return new ApplePaySession(version, applePayRequest);
};

export const disableBackForwardCaching = () => {
  // Safari does BackForward caching, lets disable that. This callback is called when Safari gets this state from history.
  const BFCacheBuster = () => {
    window.removeEventListener('pageshow', BFCacheBuster);
    window.location.reload();
  };

  // TODO: do this only for Safari
  window.addEventListener('pageshow', BFCacheBuster);
};

export const redirectBrowser = (url: string): void => {
  disableBackForwardCaching();
  window.location.href = url;
};

export const redirectToCheckoutCancelPage = (checkout: Checkout): void => {
  redirectBrowser(`${getCheckoutUrl(checkout)}cancel`);
};

export const formatDate = (date: Date): string => getDateTimeFormat(date, getClientLanguage());

export const getBaseUrl = () => {
  const split = window.location.pathname.split('/');
  return [split[0], split[1], split[2]].join('/');
};
